import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/link';

import './styles.scss';
import Button from 'components/button';

const COMPANIES = [
  '/assets/segari-logo.png',
  '/assets/trans-studio-logo.png',
  '/assets/jnt-logo.png',
  '/assets/menantea-logo.png',
];

function AboutUs() {
  return (
    <>
      <div className="aboutus">
        <section>
          <h1>Tentang Pintarnya</h1>
          <p className="about_desc">
            Pintarnya hadir menjadi lebih dari sekadar platform cari kerja tepercaya.
            Kami mendukung penuh seluruh pekerja di Indonesia untuk memiliki kehidupan
            yang lebih baik melalui pengadaan lowongan kerja dan layanan keuangan.
          </p>
        </section>

        <section>
          <h4>Kisah Kami</h4>
          <StaticImage
            src="../../assets/aboutus/aboutus-pic.png"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              aspectRatio: 1,
            }}
          />
          <p className="story_paragraf">
            Kehadiran Pintarnya dilatar belakangi atas keresahan data 60 juta pekerja
            kerah biru di Indonesia yang sulit untuk menemukan lowongan kerja terpercaya
            sehingga seringkali mengalami penipuan. Di sisi lain, perusahaan juga tidak
            memiliki cukup akses untuk mengidentifikasi dan memverifikasi para calon
            pekerjanya.
          </p>
          <p className="story_paragraf">
            Keresahan ini menggerakkan Co-Founder Pintarnya, Nelly Nurmalasari, Ghirish
            Pokardas, dan Henry Hendrawan untuk menciptakan sebuah platform pencarian
            kerja yang berfokus pada pekerja kerah biru untuk mendapatkan akses
            informasi lowongan kerja yang tepercaya dan bisa diakses dengan cepat serta
            mudah.
          </p>
          <p className="story_paragraf">
            Akhirnya, pada Mei 2022, Pintarnya diluncurkan dalam bentuk website dan
            Aplikasi Android. Dan sejak saat itu, platform Pintarnya terus berkembang
            menjadi salah satu platform pencarian kerja dengan pertumbuhan tercepat di
            Indonesia. Dalam waktu kurang dari 1 tahun sejak berdiri, Pintarnya telah
            diakses oleh lebih dari 2 juta pencari kerja.
          </p>
        </section>

        <section>
          <h4>Visi dan Misi Kami</h4>
          <div className="visimisi_title">Visi</div>
          <div className="visimisi_desc">
            Mendukung para pekerja di Indonesia melalui peningkatan akses pekerjaan,
            pendapatan, dan layanan keuangan.
          </div>

          <div className="visimisi_title">Misi</div>
          <div className="visimisi_desc">
            Mendukung para pekerja di Indonesia untuk memiliki kehidupan dan masa depan
            yang lebih baik.
          </div>
        </section>

        <section>
          <h4>Value Utama Kami</h4>
          <ul className="value_desc">
            <li>Customer Obsession</li>
            <li>Mission Driven</li>
            <li>Stay Hungry</li>
            <li>Work Hard Play Hard</li>
            <li>Fail Fast Learn Faster</li>
          </ul>
        </section>

        <section>
          <h4>Dampak Kami</h4>
          <ul className="value_desc">
            <li>
              <strong>Puluhan Ribu</strong> lowongan kerja terbaru dari toko UMKM hingga
              Perusahaan Multinasional
            </li>
            <li>
              <strong>Ratusan Ribu</strong> lamaran kerja diproses setiap minggunya
            </li>
            <li>
              <strong>Jutaan</strong> pencari kerja dari berbagai daerah di Indonesia
              telah bergabung
            </li>
            <li>
              <strong>Miliaran</strong> rupiah telah diberikan untuk para pencari kerja
              melalui fitur Cari Cuan
            </li>
          </ul>
        </section>

        <section>
          <h4>Produk Kami</h4>
          <div className="ourproduct_title">Pintarnya Cari Kerja</div>
          <div className="ourproduct_desc">
            Cari dan lamar pekerjaan impian dari ratusan ribu lowongan kerja di 300+
            Kota di Indonesia yang selalu update setiap hari.
          </div>

          <div className="ourproduct_title">Pintarnya Cari Cuan</div>
          <div className="ourproduct_desc">
            Cari penghasilan tambahan hingga ratusan ribu rupiah hanya dengan kerjakan
            misi mudah tanpa perlu keluar rumah!
          </div>

          <div className="ourproduct_title">Pintarnya Siap Kerja</div>
          <div className="ourproduct_desc">
            Akses ratusan video pembelajaran online untuk tingkatkan skill dan
            pengetahuan demi mempermudah mendapatkan pekerjaan impian.
          </div>

          <div className="ourproduct_title">Pintarnya for Employers</div>
          <div className="ourproduct_desc">
            Dapatkan kandidat terbaik dengan akses ke jutaan talenta terbaik dan gunakan
            berbagai fitur untuk pengalaman rekrutmen yang lebih efisien!
          </div>

          <div className="ourproduct_title">Pintarnya Digital Services</div>
          <div className="ourproduct_desc">
            Tingkatkan upaya dan dampak marketing digital dari bisnis Anda dengan
            kolaborasi bersama Pintarnya.
          </div>
        </section>
      </div>
      <div className="grid companies">
        <div className="start-1 col-full">
          <div className="headline_partners">
            Perusahaan yang Mempercayakan Pintarnya
          </div>
          <div className="container_partners">
            {COMPANIES.map((comp, idx) => (
              <img
                style={{ marginRight: 20 }}
                key={idx}
                src={comp}
                alt="company logo"
                height={70}
              />
            ))}
            <p className="last_item">
              <b>21,000+ </b> perusahaan/bisnis lainnya.
            </p>
          </div>
        </div>
      </div>
      <div className="aboutus">
        <div className="button_container">
          <Link to="https://pintarnya.com/kerja/home">
            <Button className="bottom_button" type="outline-blue">
              Temukan pekerjaan impianmu sekarang!
            </Button>
          </Link>
          <Link to="https://pintarnya.com/pasang-loker">
            <Button className="bottom_button" type="outline-blue">
              Temukan kandidat terbaik sekarang!
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
